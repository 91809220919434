
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const RouteChangeHandler = () => {
  const location = useLocation();

  useEffect(() => {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (e) {
      console.error("AdSense route reload error", e);
    }
  }, [location]);

  return null;
};

export default RouteChangeHandler;
