import styled, { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
    * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        font-family: 'Poppins', sans-serif;
    }

    :root {
        --primary-color: #DD2A1B;
        --paragraph-color: #333333;
        --dark-color: #000;
        --light-color: #fff;
        --light-gray-color: #B9B9B9;
        --light-red-color: #FFE9E8;
    }

    /* ✅ Google AdSense unfilled ad fix */
    ins.adsbygoogle[data-ad-status="unfilled"] {
    display: none !important;
    visibility: hidden !important;
    height: 0 !important;
    min-height: 0px !important;
    max-height: 0px !important;
  }
`;

export const MainContainer = styled.div`
  z-index: 1;
  width: 100%;
  max-width: 1300px;
  margin-top: 5rem;
  margin-bottom: 5rem;
  margin-right: auto;
  margin-left: auto;
  padding-right: 50px;
  padding-left: 50px;
  text-align: center;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  color: var(--paragraph-color);

  @media screen and (max-width: 991px) {
    margin-top: 3rem;
    margin-bottom: 3rem;
    padding-left: 30px;
    padding-right: 30px;
    font-size: 0.9rem;
    line-height: 1.4rem;
  }

  @media screen and (max-width: 480px) {
    padding-left: 15px;
    padding-right: 15px;
  }
`;

export const OtherContainer = styled.div`
  z-index: 1;
  width: 100%;
  max-width: 1300px;
  /* margin-top: 5rem; */
  margin-bottom: 5rem;
  margin-right: auto;
  margin-left: auto;
  padding-right: 50px;
  padding-left: 50px;
  text-align: center;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  color: var(--paragraph-color);

  @media screen and (max-width: 991px) {
    /* margin-top: 3rem; */
    margin-bottom: 3rem;
    padding-left: 30px;
    padding-right: 30px;
    font-size: 0.9rem;
    line-height: 1.4rem;
  }

  @media screen and (max-width: 480px) {
    padding-left: 15px;
    padding-right: 15px;
  }
`;

export const SectionTitle = styled.h2`
  font-size: 2.8rem;
  line-height: 3rem;
  font-weight: 700;
  margin-bottom: 4rem;
  color: var(--dark-color);

  @media screen and (max-width: 991px) {
    font-size: 2.4rem;
    line-height: 2.8rem;
    margin-bottom: 3rem;
  }

  @media screen and (max-width: 767px) {
    font-size: 2rem;
    line-height: 2.5rem;
    margin-bottom: 2rem;
  }

  @media screen and (max-width: 480px) {
    font-size: 1.7rem;
    line-height: 2rem;
    margin-bottom: 1.5rem;
  }
`;

export const SectionSubtitle = styled.h2`
  font-size: 1.8rem;
  line-height: 2rem;
  font-weight: 500;
  color: var(--light-gray-color);

  @media screen and (max-width: 991px) {
    font-size: 1.6rem;
    line-height: 2.1rem;
  }

  @media screen and (max-width: 767px) {
    font-size: 1.2rem;
    line-height: 1.7rem;
  }

  @media screen and (max-width: 480px) {
    font-size: 1rem;
    line-height: 1.4rem;
  }
`;

export const Head = styled.h1`
  font-size: 1.8rem;
  line-height: 2.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: var(--dark-color);

  @media screen and (max-width: 991px) {
    font-size: 1.4rem;
    line-height: 1.9rem;
  }

  @media screen and (max-width: 480px) {
    font-size: 1.1rem;
    line-height: 1.4rem;
    margin-bottom: 0.5rem;
  }
`;

export const SmallTitle = styled.div`
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 0.6rem;

  @media screen and (max-width: 991px) {
    font-size: 1.1rem;
  }

  @media screen and (max-width: 480px) {
    font-size: 1rem;
    line-height: 1.3rem;
    margin-bottom: 0.2rem;
  }
`;

export const SmallTitleLeft = styled.div`
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 0.6rem;
  text-align: left;

  @media screen and (max-width: 991px) {
    font-size: 1.1rem;
  }

  @media screen and (max-width: 480px) {
    font-size: 1rem;
    line-height: 1.3rem;
    margin-bottom: 0.2rem;
  }

  span {
    color: red;
  }
`;

export const PrimaryButtonStyles = styled.div`
  .primary-btn {
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5rem;
    text-decoration: none;
    color: var(--light-color);
    cursor: pointer;
    padding: 0.7rem 2rem;
    background-color: var(--primary-color);

    @media screen and (max-width: 991px) {
      font-size: 0.9rem;
      line-height: 1.3rem;
      padding: 0.6rem 1.5rem;
    }

    @media screen and (max-width: 480px) {
      font-size: 0.8rem;
      line-height: 1.1rem;
    }
  }
`;

export const GlobalAdSenseFix = createGlobalStyle`
  ins.adsbygoogle[data-ad-status="unfilled"] {
    display: none !important;
  }
`;

export default GlobalStyle;
