import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { NavbarStyle } from "../styles/Navbar.style";

const Navbar = () => {
  const HandleClick = (e) => {
    setToggleState(e);
  };

  const [toggleState, setToggleState] = useState(1);

  return (
    <NavbarStyle>
      <div className="navbar-inner-container">
        <div className="nav-top-wrap">
          <h1 className="nav-bold-text">
            I am <span>Vinay</span>, A Henna
          </h1>
          <div className="nav-links-wrap">
            <NavLink
              to="/"
              className={(navData) => (navData.isActive ? 'nav-link active' : 'nav-link')}
              onClick={() => HandleClick(1)}
            >
              Home
            </NavLink>
            <span className="link-divider">|</span>
            <NavLink
              to="/gallery"
              className={(navData) => (navData.isActive ? 'nav-link active' : 'nav-link')}
              onClick={() => HandleClick(2)}
            >
              Gallery
            </NavLink>
            <span className="link-divider">|</span>
            <NavLink
              to="/contact"
              className={(navData) => (navData.isActive ? 'nav-link active' : 'nav-link')}
              onClick={() => HandleClick(3)}
            >
              Contact
            </NavLink>
          </div>
        </div>
        <div className="nav-bottom-wrap">
          <h1 className="nav-bold-text">Artist And This Is What I Do Best.</h1>
        </div>
      </div>
      <div className="absolute-container">
        <Link to="/mehndi-on-airport" className="airport-link">
          <img
            src="/images/airport_3.png"
            alt=""
            className="airport-link-img"
          />
        </Link>
      </div>
    </NavbarStyle>
  );
};

export default Navbar;
