import React, { useEffect, useRef } from "react";
import AdSense from "react-adsense";

const AdBanner = ({ slot }) => {
  const adRef = useRef(null);

  useEffect(() => {
    // Only run push() if the ad hasn't already loaded
    if (window.adsbygoogle && adRef.current && !adRef.current.classList.contains("ads-loaded")) {
      try {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
        adRef.current.classList.add("ads-loaded");
      } catch (e) {
        console.error("AdSense error:", e);
      }
    }
  }, []);

  return (
    <ins
      ref={adRef}
      className="adsbygoogle"
      style={{ display: "block" }}
      data-ad-client="ca-pub-3195189751554528"
      data-ad-slot={slot}
      data-ad-format="auto"
      data-full-width-responsive="true"
    />
  );
};

export default AdBanner;
