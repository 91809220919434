import styled from "styled-components";

export const PrivacyPolicyStyles = styled.div`
    z-index: 1;
    width: 100%;
    max-width: 1300px;
    /* margin-top: 5rem; */
    margin-bottom: 5rem;
    padding-right: 50px;
    padding-left: 50px;
    text-align: left;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    color: var(--paragraph-color);

    @media screen and (max-width: 991px) {
        /* margin-top: 3rem; */
        margin-bottom: 3rem;
        padding-left: 30px;
        padding-right: 30px;
        font-size: .9rem;
        line-height: 1.4rem;
    }

    @media screen and (max-width: 480px) {
        padding-left: 15px;
        padding-right: 15px;
    }

    .top-text {
        margin-bottom: 3rem;
        
        @media screen and (max-width: 767px) {
            margin-bottom: 1.5rem;
        }
    }

    .text-wrap {
        margin-bottom: 3rem;

        &:last-child {
            margin-bottom: 0;
        }

        @media screen and (max-width: 767px) {
            margin-bottom: 1.5rem;
        }
    }
`;
