import { Helmet } from "react-helmet";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import GlobalStyle from "./GlobalStyles";
import Contact from "./pages/Contact";
import Gallery from "./pages/Gallery";
import Home from "./pages/Home";
import WhatsApp from "./components/WhatsApp";
import Error404 from "./pages/Error404";
import Airport from "./pages/Airport";
import Videos from "./pages/Videos";
import RouteChangeHandler from "./components/RouteChangeHandler";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import { useEffect, useLayoutEffect } from "react";
import AdBanner from "./components/AdBanner";

const Wrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};

function App() {
  useEffect(() => {
    const hideUnfilledAds = () => {
      document
        .querySelectorAll("ins.adsbygoogle[data-ad-status='unfilled']")
        .forEach((ad) => {
          ad.style.display = "none";
          ad.style.height = "0px";
          ad.style.visibility = "hidden";
        });
    };

    // Run once initially (in case ads are already marked as unfilled)
    hideUnfilledAds();

    // Observe DOM changes
    const observer = new MutationObserver(() => {
      hideUnfilledAds();
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });

    return () => observer.disconnect();
  }, []);

  return (
    <div className="App">
      <Wrapper>
        <Helmet>
          <title>Mehandi Studio : Best Mehandi Artists in Delhi</title>
          <link rel="canonical" href="https://www.mehandistudio.com" />
          <meta
            name="descriptions"
            content="Mehandi Studio, Best Mehandi Studio in Delhi, Best Henna Artists, Best Mehandi Designers, Best Henna Designers"
          />
          <meta
            name="keywords"
            content="mehandi, mehandi studio, mehandi artist, henna artist, bridal mehandi, bridal henna"
          />
        </Helmet>
        <GlobalStyle />
        <RouteChangeHandler />
        <Navbar />
        <WhatsApp />
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/contact" exact element={<Contact />} />
          <Route path="/gallery" exact element={<Gallery />} />
          <Route path="/mehndi-on-airport" exact element={<Airport />} />
          <Route path="/video-gallery" exact element={<Videos />} />
          <Route path="/privacy-policy" exact element={<PrivacyPolicy />} />
          <Route path="/error-404" exact element={<Error404 />} />
          <Route path="/*" element={<Navigate to="/error-404" />} />
        </Routes>
        <Footer />
        {/* <AdSenseAd slot="4679267568" /> */}
        <AdBanner slot="4679267568" />
      </Wrapper>
    </div>
  );
}

export default App;
