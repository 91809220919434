import React from "react";
import {
  Head,
  SmallTitle,
} from "../GlobalStyles";
import { PrivacyPolicyStyles } from "../styles/PrivacyPolicy.style";
import { Helmet } from "react-helmet";

const PrivacyPolicy = () => {
  return (
    <PrivacyPolicyStyles>
      <Helmet>
        <title>
          Henna Art at Indira Gandhi Airport | Convenient Mehndi Services
          On-Demand
        </title>
        <link
          rel="canonical"
          href="https://www.mehandistudio.com/mehndi-on-airport"
        />
        <meta
          name="descriptions"
          content="Enjoy beautiful henna designs at Indira Gandhi Airport with our on-demand mehndi services. Book your appointment easily via phone or email and experience exquisite mehndi art while waiting. Ideal for travelers and visitors."
        />
      </Helmet>
      <hr style={{marginBottom: "2rem"}}/>
      <Head>Privacy Policy</Head>
      <p className="top-text">
        At Mehandi Studio, your privacy is our priority. This Privacy Policy
        outlines how we collect, use, and protect your personal information when
        you visit or interact with our website.
      </p>
      <div className="text-wrap">
        <SmallTitle>Information We Collect</SmallTitle>
        <ul>
          <li>
            <p>
              <span className="bold-text">Personal Information: </span>
              When you contact us through forms or WhatsApp, we may collect your
              name, phone number, email address, and event details.
            </p>
          </li>
          <li>
            <p>
              <span className="bold-text">Usage Data: </span>
              We may gather analytics data such as IP address, browser type,
              pages visited, and time spent on the website to improve our
              services.
            </p>
          </li>
        </ul>
      </div>
      <div className="text-wrap">
        <SmallTitle>How We Use Your Information</SmallTitle>
        <ul>
          <li>
            <p>To respond to your inquiries and service requests.</p>
          </li>
          <li>
            <p>To improve user experience on our website.</p>
          </li>
          <li>
            <p>For booking and scheduling mehandi services.</p>
          </li>
          <li>
            <p>To communicate offers or updates, if you've opted in.</p>
          </li>
        </ul>
      </div>
      <div className="text-wrap">
        <SmallTitle>Third-Party Sharing</SmallTitle>
        <p>
          We do not sell or rent your personal information. We may share your
          details only with trusted partners who assist in delivering our
          services (e.g., WhatsApp API, Google Analytics), under strict
          confidentiality.
        </p>
      </div>
      <div className="text-wrap">
        <SmallTitle>Cookies</SmallTitle>
        <p>
          Our website may use cookies to enhance browsing experience. You can
          modify your browser settings to decline cookies if preferred.
        </p>
      </div>
      <div className="text-wrap">
        <SmallTitle>User Rights</SmallTitle>
        <p>You have the right to:</p>
        <ul>
          <li>
            <p>Access, update, or delete your personal data.</p>
          </li>
          <li>
            <p>Withdraw consent for promotional communication at any time.</p>
          </li>
        </ul>
        <p>
          To exercise your rights, email us at:{" "}
          <a href="mailto:vnymehndi@gmail.com" target="_blank">
            vnymehndi@gmail.com
          </a>
        </p>
      </div>
      <div className="text-wrap">
        <SmallTitle>Changes to This Policy</SmallTitle>
        <p>
          We may update this Privacy Policy from time to time. The latest
          version will always be available on this page.
        </p>
      </div>
    </PrivacyPolicyStyles>
  );
};

export default PrivacyPolicy;
